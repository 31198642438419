/* space */
.app {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

/* visual */
.app {
  background-image: url('../images/background.png');
  background-repeat: no-repeat;
}
