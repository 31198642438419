/* animation */
@keyframes slideShow {
  0% {
    right: -80%;
  }

  100% {
    right: 11%;
  }
}

@keyframes slideHide {
  0% {
    right: 11%;
  }

  100% {
    right: -80%;
  }
}

@keyframes gradientShow {
  0% {
    height: 0;
    width: 0;
    top: 50%;
    left: 50%;
    opacity: 0;
  }

  100% {
    height: 60px;
    width: 124px;
    top: -1px;
    left: -10%;
    opacity: 1;
  }
}

@keyframes gradientHide {
  0% {
    height: 60px;
    width: 124px;
    top: -1px;
    left: -10%;
    opacity: 1;
  }

  100% {
    height: 0;
    width: 0;
    top: 50%;
    left: 50%;
    opacity: 0;
  }
}

.content .content_modal.modalShow {
  animation: slideShow 0.6s ease-in;
}

.content .content_modal.modalHide {
  animation: slideHide 0.3s ease-in;
}

.content .buttons_container .colorModal.colorShow,
.content .buttons_container .gradientModal.gradientShow {
  animation: gradientShow 0.4s ease-in;
}

.content .buttons_container .colorModal.colorHide,
.content .buttons_container .gradientModal.gradientHide {
  animation: gradientHide 0.3s ease-in;
}

/* border */
.content .text_container,
.content .buttons_container .colorModal,
.content .buttons_container .gradientModal {
  border: solid 1px;
}

.content .buttons_container .colorModal.colorHide,
.content .buttons_container .colorModal.colorNull,
.content .buttons_container .gradientModal.gradientHide,
.content .buttons_container .gradientModal.gradientNull {
  border: none;
}

.content textarea,
.content .buttons_container .colorModal,
.content .buttons_container .gradientModal {
  border-radius: 10px;
}

.content .buttons_container button,
.content .actions_container button {
  border: solid 1px;
  border-radius: 5px;
}

.content .content_wrapper {
  border-left: solid 1px;
  border-right: solid 1px;
}

/* color */
.content .content_wrapper,
.content .buttons_container .colorModal,
.content .buttons_container .gradientModal,
.content .text_container {
  border-color: #F5D765;
}

.content .content_wrapper {
  background-color: rgba(31, 23, 15, 0.1);
}

.content .buttons_container .colorModal,
.content .buttons_container .gradientModal {
  background-color: #282428;
}

.content .content_modal {
  background-color: #F3F0E9;
}

.content textarea {
  background-color: #F3F0E9;
  border-color: #6D6051;
  color: #282428;
  outline-color: #D5934A;
}

.content .buttons_container button,
.content .buttons_container .color_container,
.content .buttons_container .gradient_container,
.content .actions_container button {
  background: none;
  color: #F5D765;
  border-color: #F5D765;
}

.content .text_container {
  background-color: #D7D3CA;
}

.content .buttons_container button:active,
.content .actions_container button:active {
  background-color: #F5D765;
  color: #503B25;
}

.content .copyBtn_container .success {
  color: #C5FA8A;
}

.content .copyBtn_container .failure,
.content .warning_container {
  color: #EC0046;
}

.content .warning_container {
  text-shadow: #282428 0 0 2px;
}

@media only screen and (hover: hover) {
  .content .buttons_container button:hover,
  .content .actions_container button:hover {
    background-color: #F5D765;
    color: #503B25;
  }
}

/* flex */
.content,
.content .content_wrapper,
.content .content_wrapper .buttons_container,
.content .buttons_container .scg_button_container,
.content .buttons_container .colorModal,
.content .buttons_container .colorModal .color_selector_wrapper,
.content .buttons_container .colorModal .color_selector_wrapper div,
.content .buttons_container .colorModal .color_action_wrapper div,
.content .buttons_container .gradientModal,
.content .buttons_container .gradientModal .gradient_selector_wrapper,
.content .buttons_container .gradientModal .gradient_selector_wrapper div,
.content .buttons_container .gradientModal .gradient_action_wrapper div,
.content .actions_container,
.content .content_modal,
.content .text_container {
  display: flex;
}

.content,
.content .buttons_container .colorModal,
.content .buttons_container .colorModal .color_selector_wrapper,
.content .buttons_container .colorModal .color_selector_wrapper div,
.content .buttons_container .colorModal .color_action_wrapper div,
.content .buttons_container .gradientModal,
.content .buttons_container .gradientModal .gradient_selector_wrapper div,
.content .buttons_container .gradientModal .gradient_action_wrapper div,
.content .content_modal {
  align-items: center;
  justify-content: center;
}

.content
.content .content_wrapper .buttons_container,
.content .content_wrapper,
.content .buttons_container .colorModal,
.content .buttons_container .colorModal .color_selector_wrapper div,
.content .buttons_container .gradientModal,
.content .buttons_container .gradientModal .gradient_selector_wrapper div {
  flex-direction: column;
}

.content .content_wrapper {
  align-items: center;
}

.content .buttons_container .scg_button_container,
.content .buttons_container,
.content .text_container {
  flex-wrap: wrap;
}

.content .actions_container {
  justify-content: space-between;
}

.content .buttons_container .colorModal,
.content .buttons_container .gradientModal {
  justify-content: space-around;
}

/* font */
.content textarea,
.content .text_container {
  font-family: UniversLTStd, sans-serif;
}

.content .warning_container {
  font-family: UniversLTStd-Light, sans-serif;
}

.content .buttons_container button,
.content .buttons_container .color_container,
.content .buttons_container .gradient_container,
.content .actions_container button {
  font-family: UniversLTStd-Bold, sans-serif;
}

.content .buttons_container .bold {
  font-family: UniversLTStd-XBlack, sans-serif;
}

.content .buttons_container .italic {
  font-family: UniversLTStd-BoldObl, sans-serif;
}

.content .buttons_container .underline {
  text-decoration: underline;
}

.content .buttons_container .strike {
  text-decoration: line-through;
}

.content .copyBtn_container span {
  font-family: UniversLTStd, sans-serif;
}

.content .buttons_container .colorModal,
.content .buttons_container .gradientModal {
  font-size: 16px;
}

.content .warning_container {
  font-size: 12px;
}

.content textarea,
.content .text_container {
  word-break: break-all;
}

@media only screen and (min-width: 400px) {
  .content textarea,
  .content .text_container,
  .content .buttons_container button,
  .content .buttons_container .color_container,
  .content .buttons_container .gradient_container,
  .content .actions_container button {
    font-size: 16px;
  }
}

@media only screen and (min-width: 550px) {
  .content textarea,
  .content .text_container,
  .content .buttons_container button,
  .content .buttons_container .color_container,
  .content .buttons_container .gradient_container,
  .content .actions_container button {
    font-size: 18px;
  }

  .content .buttons_container .color_action_wrapper button,
  .content .buttons_container .gradient_action_wrapper button,
  .content .warning_container {
    font-size: 16px;
  }
}

@media only screen and (min-width: 850px) {
  .content textarea,
  .content .text_container,
  .content .buttons_container button,
  .content .buttons_container .color_container,
  .content .buttons_container .gradient_container,
  .content .actions_container button {
    font-size: 20px;
  }
}

@media only screen and (min-width: 880px) {
  .content textarea,
  .content .text_container,
  .content .buttons_container button,
  .content .buttons_container .color_container,
  .content .buttons_container .gradient_container,
  .content .actions_container button {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1200px) {
  .content .buttons_container button,
  .content .buttons_container .color_container,
  .content .buttons_container .gradient_container,
  .content .actions_container button {
    font-size: 24px;
  }
}

/* position */
.content .content_wrapper,
.content .buttons_container button.color,
.content .buttons_container .color_container,
.content .buttons_container .gradient_container,
.content .text_container {
  position: relative;
}

.content .warning_container,
.content .buttons_container .colorModal,
.content .buttons_container .gradientModal,
.content .content_modal,
.content .text_container .corner_c {
  position: absolute;
}

.content .warning_container {
  top: 2px;
}

.content .buttons_container .sup {
  position: relative;
  top: 2px;
}

.content .content_modal {
  top: 10px;
  right: -80%;
}

.content .content_modal.modalShow {
  right: 11%;
}

.content .buttons_container #colorInput {
  top: 0;
  left: 0;
}

.content .buttons_container .colorModal,
.content .buttons_container .gradientModal {
  top: -50px;
  left: -60%;
}

@media only screen and (min-width: 321px) {
  .content .buttons_container .colorModal,
  .content .buttons_container .gradientModal {
    left: -10%;
  }
}

@media only screen and (min-width: 400px) {
  .content .buttons_container .sup {
    top: 3px;
  }

  .content .content_modal {
    top: 10px;
  }
}

@media only screen and (min-width: 550px) {
  .content .buttons_container .colorModal,
  .content .buttons_container .gradientModal {
    left: -10%;
  }
}

@media only screen and (min-width: 700px) {
  .content .content_modal {
    top: 20px;
  }
}

@media only screen and (min-width: 800px) {
  .content .content_modal {
    top: 20px;
  }
}

@media only screen and (min-width: 850px) {
  .content .buttons_container .colorModal,
  .content .buttons_container .gradientModal {
    left: 0;
  }
}

@media only screen and (min-width: 900px) {
  .content .content_modal {
    top: 20px;
  }
}

/* space */
.content {
  width: 100%;
}

.content .content_wrapper {
  width: 90%;
  padding: 10px 0;
}

.content textarea,
.content .buttons_container,
.content .text_container,
.content .actions_container {
  width: 80%;
}

.content .content_modal {
  width: 78%;
}

.content .buttons_container .colorModal,
.content .buttons_container .gradientModal {
  height: 90px;
  width: 124px;
}

.content .buttons_container .colorModal.colorHide,
.content .buttons_container .colorModal.colorNull,
.content .buttons_container .gradientModal.gradientHide,
.content .buttons_container .gradientModal.gradientNull {
  height: 0;
  width: 0;
}

.content .buttons_container .colorModal input,
.content .buttons_container .gradientModal input {
  height: 25px;
  width: 25px;
}

.content .buttons_container .gradientModal .gradient_start_wrapper {
  margin-right: 10px;
}

.content .buttons_container .colorModal input,
.content .buttons_container .gradientModal input {
  margin-bottom: 2px;
}

.content textarea,
.content .buttons_container,
.content .text_container {
  margin-bottom: 20px;
}

.content textarea,
.content .text_container {
  height: 100px;
}

.content .content_modal {
  height: 96%;
}

.content textarea {
  padding: 10px;
}

.content .text_container {
  padding: 6px;
}

.content .text_container div {
  height: fit-content;
}

.content .buttons_container button {
  margin: 6px;
  height: 25px;
  padding: 1px 10px;
}

.content .buttons_container .color_action_wrapper button,
.content .buttons_container .gradient_action_wrapper button {
  margin: 0;
  padding: 2px;
}

.content .text_container .corner_c {
  height: 16px;
  width: 30px;
}

.content .text_container .display_text {
  min-width: 0.3rem;
}

.content .copyBtn_container button {
  margin-right: 5px;
}

@media only screen and (min-width: 200px) {
  .content .content_modal {
    height: 469.5px;
  }
}

@media only screen and (min-width: 238px) {
  .content .content_modal {
    height: 434px;
  }
}

@media only screen and (min-width: 321px) {
  .content .content_modal {
    height: 404px;
  }
}

@media only screen and (min-width: 400px) {
  .content .content_wrapper {
    padding: 20px 0;
  }

  .content textarea,
  .content .buttons_container,
  .content .text_container {
    margin-bottom: 40px;
  }

  .content textarea,
  .content .text_container {
    height: 100px;
  }
}

@media only screen and (min-width: 462px) {
  .content .buttons_container button {
    margin-bottom: 12px;
  }
}

@media only screen and (min-width: 550px) {
  .content .content_wrapper {
    width: 80%;
  }

  .content textarea {
    padding: 10px;
  }

  .content .buttons_container .text_buttons_container button,
  .content .buttons_container .sprite,
  .content .buttons_container .color,
  .content .buttons_container .gradient {
    height: 32px;
  }

  .content .text_container .corner_c {
    height: 20px;
    width: 38px;
  }
}

@media only screen and (min-width: 700px) {
  .content textarea,
  .content .buttons_container,
  .content .text_container,
  .content .actions_container,
  .content .content_modal {
    width: 75%;
  }

  .content .content_modal {
    height: 92%;
  }

  .content .content_modal.modalShow {
    right: 12.5%;
  }

  .content textarea,
  .content .buttons_container,
  .content .text_container {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 800px) {
  .content textarea,
  .content .text_container {
    height: 90px;
  }
}

@media only screen and (min-width: 900px) {
  .content .content_wrapper {
    width: 720px;
    padding: 30px 0;
  }

  .content textarea,
  .content .buttons_container,
  .content .text_container,
  .content .actions_container,
  .content .content_modal {
    width: 540px;
  }

  .content .content_modal {
    height: 92%;
  }
}

@media only screen and (min-width: 1200px) {
  .content .buttons_container .text_buttons_container button,
  .content .buttons_container .sprite,
  .content .buttons_container .color,
  .content .buttons_container .gradient {
    height: 36px;
  }

  .content .content_modal {
    height: 92%;
  }

  .content .buttons_container .colorModal,
  .content .buttons_container .gradientModal {
    width: 140px;
  }
}

/* other */
.content .buttons_container button,
.content .actions_container button,
.content .buttons_container #colorInput,
.content .buttons_container .colorModal,
.content .buttons_container .gradientModal {
  cursor: pointer;
}

.content .content_wrapper,
.content .buttons_container .colorModal,
.content .buttons_container .gradientModal {
  overflow: hidden;
}

.content .text_container {
  overflow-y: scroll;
  overflow-x: hidden;
}

.content .buttons_container .colorModal,
.content .buttons_container .gradientModal {
  z-index: 1;
}

.warning_container,
.warning_container.warning_hide {
  opacity: 0;
}

.warning_container.warning_show {
  opacity: 1;
}
