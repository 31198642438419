/* border */
.header .underline {
  border-bottom: solid 1px;
}

/* color */
.header h1 {
  color: #D5934A;
}

.header .underline {
  border-color: #D5934A;
}

/* flex */
.header {
  display: flex;
  justify-content: center;
}

/* font */
.header h1 {
  font-family: BeaufortforLOL-Regular, sans-serif;
  margin: 0;
}

.header .header_main {
  font-size: 20px;
}

.header .header_end {
  font-size: 28px;
}

@media only screen and (min-width: 400px) {
  .header .header_main {
    font-size: 28px;
  }

  .header .header_end {
    font-size: 36px;
  }
}

@media only screen and (min-width: 550px) {
  .header .header_main {
    font-size: 36px;
  }

  .header .header_end {
    font-size: 48px;
  }
}

@media only screen and (min-width: 900px) {
  .header .header_main {
    font-size: 48px;
  }

  .header .header_end {
    font-size: 64px;
  }
}

/* position */
.header .header_end {
  position: relative;
  top: -3px;
}

@media only screen and (min-width: 550px) {
  .header .header_end {
    position: relative;
    top: -4px;
  }
}

@media only screen and (min-width: 900px) {
  .header .header_end {
    position: relative;
    top: -5px;
  }
}

/* space */
.header {
  width: 100%;
}

.header .header_end,
.header .underline {
  margin: 30px 0 40px 0;
}

.header .underline {
  height: 100%;
}

@media only screen and (min-width: 400px) {
  .header .header_end,
  .header .underline {
    margin: 40px 0 40px 0;
  }
}

@media only screen and (min-width: 700px) {

  .header .header_end,
  .header .underline {
    margin: 20px 0 20px 0;
  }
}
