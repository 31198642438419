/* font */
@font-face {
  font-family: 'BeaufortforLOL-Bold';
  src: url('../fonts/BeaufortForLoL-OTF/BeaufortforLOL-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'BeaufortforLOL-BoldItalic';
  src: url('../fonts/BeaufortForLoL-OTF/BeaufortforLOL-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'BeaufortforLOL-Heavy';
  src: url('../fonts/BeaufortForLoL-OTF/BeaufortforLOL-Heavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'BeaufortforLOL-HeavyItalic';
  src: url('../fonts/BeaufortForLoL-OTF/BeaufortforLOL-HeavyItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'BeaufortforLOL-Italic';
  src: url('../fonts/BeaufortForLoL-OTF/BeaufortforLOL-Italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'BeaufortforLOL-Light';
  src: url('../fonts/BeaufortForLoL-OTF/BeaufortforLOL-Light.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'BeaufortforLOL-LightItalic';
  src: url('../fonts/BeaufortForLoL-OTF/BeaufortforLOL-LightItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'BeaufortforLOL-Medium';
  src: url('../fonts/BeaufortForLoL-OTF/BeaufortforLOL-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'BeaufortforLOL-MediumItalic';
  src: url('../fonts/BeaufortForLoL-OTF/BeaufortforLOL-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'BeaufortforLOL-Regular';
  src: url('../fonts/BeaufortForLoL-OTF/BeaufortforLOL-Regular.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'UniversLTStd';
  src: url('../fonts/Univers/UniversLTStd.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'UniversLTStd-Black';
  src: url('../fonts/Univers/UniversLTStd-Black.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'UniversLTStd-BlackObl';
  src: url('../fonts/Univers/UniversLTStd-BlackObl.otf') format('opentype');
  font-weight: 700;
  font-style: oblique;
}

@font-face {
  font-family: 'UniversLTStd-Bold';
  src: url('../fonts/Univers/UniversLTStd-Bold.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'UniversLTStd-BoldObl';
  src: url('../fonts/Univers/UniversLTStd-BoldObl.otf') format('opentype');
  font-weight: 500;
  font-style: oblique;
}

@font-face {
  font-family: 'UniversLTStd-Cn';
  src: url('../fonts/Univers/UniversLTStd-Cn.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'UniversLTStd-CnObl';
  src: url('../fonts/Univers/UniversLTStd-CnObl.otf') format('opentype');
  font-weight: 300;
  font-style: oblique;
}

@font-face {
  font-family: 'UniversLTStd-Light';
  src: url('../fonts/Univers/UniversLTStd-Light.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'UniversLTStd-LightObl';
  src: url('../fonts/Univers/UniversLTStd-LightObl.otf') format('opentype');
  font-weight: 100;
  font-style: oblique;
}

@font-face {
  font-family: 'UniversLTStd-Obl';
  src: url('../fonts/Univers/UniversLTStd-Obl.otf') format('opentype');
  font-weight: 300;
  font-style: oblique;
}

@font-face {
  font-family: 'UniversLTStd-XBlack';
  src: url('../fonts/Univers/UniversLTStd-XBlack.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'UniversLTStd-XBlackObl';
  src: url('../fonts/Univers/UniversLTStd-XBlackObl.otf') format('opentype');
  font-weight: 900;
  font-style: oblique;
}
