/* space */
* {
  box-sizing: border-box;
}

body,
#root {
  width: 100%;
  padding: 0;
  margin: 0;
}
